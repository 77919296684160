<template>
  <div class="zh-main-app shelf-layout">
    <div class="zh-main-content ">
      <div class="page-header">
        <div class="page-header-text cur_p" @click="back">货架管理</div>
        <div class="page-header-splitter">&gt;</div>
        <div class="page-header-text active">{{ routeText }}</div>
        <div v-if="fullPath === '/personal/shelfManagement/update'" class="page-header-update-text">
          货架列表：默认货位全部启用，如不需要某货位，取消勾选即可。（不影响货位中已上架产品）
        </div>
      </div>
      <router-view v-slot="{ Component }">
        <div :key="route.path" class="box">
          <component :is="Component"></component>
        </div>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";

const route = useRoute()
const fullPath = route.path
const routeText = route.meta.text
const router = useRouter()

const back = () => {
  router.push({
    path: '/personal/shelfManagement'
  })
}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "",
});
</script>

<style lang="scss" scoped>
@import "../common.scss";

.box {
  padding-top: 14px;
}
</style>
